import {AppContextStore, AsodVariant} from "./AppContextStore";
import {proto} from "../proto/messages";
import {makeAutoObservable} from "mobx";
import {api_general} from "../proto/compiled";
import {ErrorUtils} from "../utils/ErrorUtils";
import {RecorderRoutes} from "../recorder/Routes";
import {CookieUtils} from "../utils/CookieUtils";

export class AuthStore {
    context: AppContextStore;
    user?: api_general.IApiGeneralWhoAmIResponse;
    ssoUrl?: string;
    private token?: string;
    static authTokenKey = 'authToken';

    constructor(context: AppContextStore) {
        this.context = context;
        this.context.api.getMessageHandler(new proto.RxApiGeneralWhoAmIResponse()).subscribe((e) => this.onWhoAmI(e));
        this.context.api.getMessageHandler(new proto.RxApiPrijavaAuthChallenge()).subscribe((e) => this.onAuthChallenge(e));
        this.context.api.getMessageHandler(new proto.RxApiPrijavaAuthToken()).subscribe((e) => this.onAuthToken(e));
        this.context.api.getMessageHandler(new proto.RxTokenInvalid()).subscribe((e) => this.onTokenInvalid());
        this.context.api.getMessageHandler(new proto.RxIlolError()).subscribe((e) => {
            if (e.proto.reason === 'Not authenticated') {
                this.onTokenInvalid();
            }
        });
        makeAutoObservable(this);
        const token = document.cookie.split('; ').filter(row => row.startsWith('auth=')).map(c => c.split('=')[1])[0];
        if (!this.context.isRecorder && token?.length) {
            this.token = token;
            this.context.api.setAuth(this.token);
        }
    }

    get loggedInAndHasUser() {
        return this.loggedIn && this.hasUser;
    }

    get hasUser() {
        return !!this.user;
    }

    get loggedIn() {
        return !!this.token;
    }

    get userName() {
        return this.user?.displayName;
    }

    get role() {
        return this.context.constants.roles[this.user?.userId ?? '']?.title;
    }

    logout() {
        if (this.context.isRecorder) {
            this.context.api.sendMessage(proto.TxAsodLogout.create());
        } else {
            CookieUtils.delete(AuthStore.authTokenKey);
        }
        this.onTokenInvalid()
    }

    async login() {
        const response = await this.context.api.sendMessage(proto.TxApiPrijavaRequestAuth.create({
            redirectUrl: RecorderRoutes.waitForAuth,
        }), {ack: this.context.isRecorder});
        ErrorUtils.response(response);
    }

    private onAuthChallenge(m: proto.RxApiPrijavaAuthChallenge) {
        window.location.href = m.proto.ssoRedirectUrl;
    }

    private onAuthToken(e: proto.RxApiPrijavaAuthToken) {
        this.ssoUrl = undefined;
        this.token = e.proto.authToken;
        if (!this.context.isRecorder) {
            this.context.api.setAuth(this.token);
            window.localStorage.setItem(AuthStore.authTokenKey, this.token);
        }
    }

    private onWhoAmI(e: proto.RxApiGeneralWhoAmIResponse) {
        this.user = e.proto;
    }

    private onTokenInvalid() {
        this.user = undefined;
        this.token = undefined;
        this.context.constants.roles = {};
        if (!this.context.isRecorder) {
            this.context.api.setAuth(null);
            window.localStorage.removeItem(AuthStore.authTokenKey);
        }
    }
}