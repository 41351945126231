import React, {useEffect, useMemo} from 'react';
import './App.css';
import {RecorderApp} from "./recorder/RecorderApp";
import {WebserviceApp} from "./webservice/WebserviceApp";
import {AppContextStore, AppContextStoreContext, AsodVariant} from "./stores/AppContextStore";
import {SocketApi, SocketApiVariant} from "proto_socket_typescript";
import {Frame} from "./components/elementary/Frame";
import {D} from "./app/constants";
import {InfoOutlined} from "@mui/icons-material";
import {CircularProgress, ThemeProvider, Typography} from "@mui/material";
import {DarkTheme} from "./app/theme";
import {LoadingOverlay} from "./components/elementary/LoadingOverlay";

function App() {
    if (window.location.pathname === '/auth/wait') return <AuthWaitApp/>;
    return <MainApp/>;
}

function AuthWaitApp() {
    return <ThemeProvider theme={DarkTheme}>
        <Frame fill center height={'100vh'}>
            <Frame appearance={'filled-outline'} padding={2} row gap={2} center
                   style={{maxWidth: '700px'}}>
                <CircularProgress/>
                <Typography variant={'h5'}>
                    Čakanje na odobritev prijave
                </Typography>
            </Frame>
        </Frame>
    </ThemeProvider>;
}

function MainApp() {
    const [isConnected, setIsConnected] = React.useState(false);

    const api = useMemo(() => {
        let endpoint;
        let variant = SocketApiVariant.proto;
        switch (AppContextStore.variant) {
            case AsodVariant.asodout:
                endpoint = process.env.REACT_APP_OUT_ENDPOINT;
                variant = SocketApiVariant.ilol;
                break;
            case AsodVariant.asodint:
                endpoint = process.env.REACT_APP_INT_ENDPOINT;
                variant = SocketApiVariant.ilol;
                break;
            case AsodVariant.recorder:
                endpoint = process.env.REACT_APP_RECORDER_ENDPOINT;
                break;
            default:
                throw new Error('Unknown variant: ' + process.env.REACT_APP_VARIANT);
        }
        console.log('Connecting to endpoint: ' + endpoint)
        return new SocketApi(endpoint, variant);
    }, []);
    const app = useMemo(() => new AppContextStore(api), [api]);

    useEffect(() => {
        if (api.connection.connected !== isConnected) {
            setIsConnected(api.connection.connected);
        }
        const sub = api.connection.connectedChanges.subscribe((isConnected) => {
            setIsConnected(isConnected);
        });
        return () => sub.unsubscribe();
    }, [api]);

    let index;
    if ([AsodVariant.asodout, AsodVariant.asodint].includes(AppContextStore.variant)) {
        index = <WebserviceApp/>
    } else if (AppContextStore.variant === AsodVariant.recorder) {
        index = <RecorderApp/>
    } else {
        index = <div>Unknown variant: {process.env.REACT_APP_VARIANT}</div>
    }

    return <AppContextStoreContext.Provider value={app}>
        {index}
        <LoadingOverlay visible={!isConnected}/>
    </AppContextStoreContext.Provider>
}

export default App;
