import {asod_audio, ws_logs, uploader, api_pairing, asod_auth, ws_users, api_pregled, asod_project, api_snemalniki, api_konstante, api_obravnave, api_general, asod_recorder_project, api_komentarji, ws_management, socket_api, api_logs, sfiles, api_integriteta, api_prijava, asod_recorder_audio, api_udelezenci, authentication, recorder_pairing, api_deeplinks, recorder_state, ws_home, asod_members} from "./compiled";
import {SocketRxMessage, SocketRxMessageData, SocketTxMessage} from "proto_socket_typescript";

export namespace proto {

export class TxApiDeeplinksAddDeeplink extends SocketTxMessage<api_deeplinks.ApiDeeplinksAddDeeplink> {
    static type: string = 'api-deeplinks|add-deeplink';
    proto: api_deeplinks.ApiDeeplinksAddDeeplink;
    protoClass = api_deeplinks.ApiDeeplinksAddDeeplink;
    

    constructor(proto: api_deeplinks.ApiDeeplinksAddDeeplink) {
        super(TxApiDeeplinksAddDeeplink.type, true);
        this.proto = proto;
    }

    static create(properties: api_deeplinks.IApiDeeplinksAddDeeplink = {}) {
        return new TxApiDeeplinksAddDeeplink(api_deeplinks.ApiDeeplinksAddDeeplink.create(properties));
    }
}


export class TxApiDeeplinksGetSessionDeeplinks extends SocketTxMessage<api_deeplinks.ApiDeeplinksGetSessionDeeplinks> {
    static type: string = 'api-deeplinks|get-session-deeplinks';
    proto: api_deeplinks.ApiDeeplinksGetSessionDeeplinks;
    protoClass = api_deeplinks.ApiDeeplinksGetSessionDeeplinks;
    

    constructor(proto: api_deeplinks.ApiDeeplinksGetSessionDeeplinks) {
        super(TxApiDeeplinksGetSessionDeeplinks.type, true);
        this.proto = proto;
    }

    static create(properties: api_deeplinks.IApiDeeplinksGetSessionDeeplinks = {}) {
        return new TxApiDeeplinksGetSessionDeeplinks(api_deeplinks.ApiDeeplinksGetSessionDeeplinks.create(properties));
    }
}


export class TxApiDeeplinksRemoveDeeplink extends SocketTxMessage<api_deeplinks.ApiDeeplinksRemoveDeeplink> {
    static type: string = 'api-deeplinks|remove-deeplink';
    proto: api_deeplinks.ApiDeeplinksRemoveDeeplink;
    protoClass = api_deeplinks.ApiDeeplinksRemoveDeeplink;
    

    constructor(proto: api_deeplinks.ApiDeeplinksRemoveDeeplink) {
        super(TxApiDeeplinksRemoveDeeplink.type, true);
        this.proto = proto;
    }

    static create(properties: api_deeplinks.IApiDeeplinksRemoveDeeplink = {}) {
        return new TxApiDeeplinksRemoveDeeplink(api_deeplinks.ApiDeeplinksRemoveDeeplink.create(properties));
    }
}


export class RxApiDeeplinksSessionDeeplinks extends SocketRxMessage<api_deeplinks.ApiDeeplinksSessionDeeplinks> {
    static type: string = 'api-deeplinks|session-deeplinks';
    proto = api_deeplinks.ApiDeeplinksSessionDeeplinks.create({});
    protoClass = api_deeplinks.ApiDeeplinksSessionDeeplinks;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiDeeplinksSessionDeeplinks.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiDeeplinksSessionDeeplinks(message);
    };
}


export class TxApiDeeplinksUpdateDeeplink extends SocketTxMessage<api_deeplinks.ApiDeeplinksUpdateDeeplink> {
    static type: string = 'api-deeplinks|update-deeplink';
    proto: api_deeplinks.ApiDeeplinksUpdateDeeplink;
    protoClass = api_deeplinks.ApiDeeplinksUpdateDeeplink;
    

    constructor(proto: api_deeplinks.ApiDeeplinksUpdateDeeplink) {
        super(TxApiDeeplinksUpdateDeeplink.type, true);
        this.proto = proto;
    }

    static create(properties: api_deeplinks.IApiDeeplinksUpdateDeeplink = {}) {
        return new TxApiDeeplinksUpdateDeeplink(api_deeplinks.ApiDeeplinksUpdateDeeplink.create(properties));
    }
}


export class RxApiGeneralAck extends SocketRxMessage<api_general.ApiGeneralAck> {
    static type: string = 'api-general|ack';
    proto = api_general.ApiGeneralAck.create({});
    protoClass = api_general.ApiGeneralAck;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiGeneralAck.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiGeneralAck(message);
    };
}


export class TxApiGeneralWhoAmI extends SocketTxMessage<api_general.ApiGeneralWhoAmI> {
    static type: string = 'api-general|who-am-i';
    proto: api_general.ApiGeneralWhoAmI;
    protoClass = api_general.ApiGeneralWhoAmI;
    

    constructor(proto: api_general.ApiGeneralWhoAmI) {
        super(TxApiGeneralWhoAmI.type, true);
        this.proto = proto;
    }

    static create(properties: api_general.IApiGeneralWhoAmI = {}) {
        return new TxApiGeneralWhoAmI(api_general.ApiGeneralWhoAmI.create(properties));
    }
}


export class RxApiGeneralWhoAmIResponse extends SocketRxMessage<api_general.ApiGeneralWhoAmIResponse> {
    static type: string = 'api-general|who-am-i-response';
    proto = api_general.ApiGeneralWhoAmIResponse.create({});
    protoClass = api_general.ApiGeneralWhoAmIResponse;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiGeneralWhoAmIResponse.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiGeneralWhoAmIResponse(message);
    };
}


export class TxApiGeneralWhoArePersonUuids extends SocketTxMessage<api_general.ApiGeneralWhoArePersonUuids> {
    static type: string = 'api-general|who-are-person-uuids';
    proto: api_general.ApiGeneralWhoArePersonUuids;
    protoClass = api_general.ApiGeneralWhoArePersonUuids;
    

    constructor(proto: api_general.ApiGeneralWhoArePersonUuids) {
        super(TxApiGeneralWhoArePersonUuids.type, true);
        this.proto = proto;
    }

    static create(properties: api_general.IApiGeneralWhoArePersonUuids = {}) {
        return new TxApiGeneralWhoArePersonUuids(api_general.ApiGeneralWhoArePersonUuids.create(properties));
    }
}


export class RxApiGeneralWhoArePersonUuidsResponse extends SocketRxMessage<api_general.ApiGeneralWhoArePersonUuidsResponse> {
    static type: string = 'api-general|who-are-person-uuids-response';
    proto = api_general.ApiGeneralWhoArePersonUuidsResponse.create({});
    protoClass = api_general.ApiGeneralWhoArePersonUuidsResponse;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiGeneralWhoArePersonUuidsResponse.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiGeneralWhoArePersonUuidsResponse(message);
    };
}


export class TxApiIntegritetaCancelVerification extends SocketTxMessage<api_integriteta.ApiIntegritetaCancelVerification> {
    static type: string = 'api-integriteta|cancel-verification';
    proto: api_integriteta.ApiIntegritetaCancelVerification;
    protoClass = api_integriteta.ApiIntegritetaCancelVerification;
    

    constructor(proto: api_integriteta.ApiIntegritetaCancelVerification) {
        super(TxApiIntegritetaCancelVerification.type, true);
        this.proto = proto;
    }

    static create(properties: api_integriteta.IApiIntegritetaCancelVerification = {}) {
        return new TxApiIntegritetaCancelVerification(api_integriteta.ApiIntegritetaCancelVerification.create(properties));
    }
}


export class TxApiIntegritetaGetVerificationHistory extends SocketTxMessage<api_integriteta.ApiIntegritetaGetVerificationHistory> {
    static type: string = 'api-integriteta|get-verification-history';
    proto: api_integriteta.ApiIntegritetaGetVerificationHistory;
    protoClass = api_integriteta.ApiIntegritetaGetVerificationHistory;
    

    constructor(proto: api_integriteta.ApiIntegritetaGetVerificationHistory) {
        super(TxApiIntegritetaGetVerificationHistory.type, true);
        this.proto = proto;
    }

    static create(properties: api_integriteta.IApiIntegritetaGetVerificationHistory = {}) {
        return new TxApiIntegritetaGetVerificationHistory(api_integriteta.ApiIntegritetaGetVerificationHistory.create(properties));
    }
}


export class RxApiIntegritetaVerificationHistory extends SocketRxMessage<api_integriteta.ApiIntegritetaVerificationHistory> {
    static type: string = 'api-integriteta|verification-history';
    proto = api_integriteta.ApiIntegritetaVerificationHistory.create({});
    protoClass = api_integriteta.ApiIntegritetaVerificationHistory;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiIntegritetaVerificationHistory.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiIntegritetaVerificationHistory(message);
    };
}


export class TxApiIntegritetaVerifyAllSignatures extends SocketTxMessage<api_integriteta.ApiIntegritetaVerifyAllSignatures> {
    static type: string = 'api-integriteta|verify-all-signatures';
    proto: api_integriteta.ApiIntegritetaVerifyAllSignatures;
    protoClass = api_integriteta.ApiIntegritetaVerifyAllSignatures;
    

    constructor(proto: api_integriteta.ApiIntegritetaVerifyAllSignatures) {
        super(TxApiIntegritetaVerifyAllSignatures.type, true);
        this.proto = proto;
    }

    static create(properties: api_integriteta.IApiIntegritetaVerifyAllSignatures = {}) {
        return new TxApiIntegritetaVerifyAllSignatures(api_integriteta.ApiIntegritetaVerifyAllSignatures.create(properties));
    }
}


export class TxApiIntegritetaVerifySignatures extends SocketTxMessage<api_integriteta.ApiIntegritetaVerifySignatures> {
    static type: string = 'api-integriteta|verify-signatures';
    proto: api_integriteta.ApiIntegritetaVerifySignatures;
    protoClass = api_integriteta.ApiIntegritetaVerifySignatures;
    

    constructor(proto: api_integriteta.ApiIntegritetaVerifySignatures) {
        super(TxApiIntegritetaVerifySignatures.type, true);
        this.proto = proto;
    }

    static create(properties: api_integriteta.IApiIntegritetaVerifySignatures = {}) {
        return new TxApiIntegritetaVerifySignatures(api_integriteta.ApiIntegritetaVerifySignatures.create(properties));
    }
}


export class TxApiKomentarjiAddAnnotation extends SocketTxMessage<api_komentarji.ApiKomentarjiAddAnnotation> {
    static type: string = 'api-komentarji|add-annotation';
    proto: api_komentarji.ApiKomentarjiAddAnnotation;
    protoClass = api_komentarji.ApiKomentarjiAddAnnotation;
    

    constructor(proto: api_komentarji.ApiKomentarjiAddAnnotation) {
        super(TxApiKomentarjiAddAnnotation.type, true);
        this.proto = proto;
    }

    static create(properties: api_komentarji.IApiKomentarjiAddAnnotation = {}) {
        return new TxApiKomentarjiAddAnnotation(api_komentarji.ApiKomentarjiAddAnnotation.create(properties));
    }
}


export class TxApiKomentarjiGetSessionAnnotations extends SocketTxMessage<api_komentarji.ApiKomentarjiGetSessionAnnotations> {
    static type: string = 'api-komentarji|get-sesion-annotations';
    proto: api_komentarji.ApiKomentarjiGetSessionAnnotations;
    protoClass = api_komentarji.ApiKomentarjiGetSessionAnnotations;
    

    constructor(proto: api_komentarji.ApiKomentarjiGetSessionAnnotations) {
        super(TxApiKomentarjiGetSessionAnnotations.type, true);
        this.proto = proto;
    }

    static create(properties: api_komentarji.IApiKomentarjiGetSessionAnnotations = {}) {
        return new TxApiKomentarjiGetSessionAnnotations(api_komentarji.ApiKomentarjiGetSessionAnnotations.create(properties));
    }
}


export class TxApiKomentarjiRemoveAnnotation extends SocketTxMessage<api_komentarji.ApiKomentarjiRemoveAnnotation> {
    static type: string = 'api-komentarji|remove-annotation';
    proto: api_komentarji.ApiKomentarjiRemoveAnnotation;
    protoClass = api_komentarji.ApiKomentarjiRemoveAnnotation;
    

    constructor(proto: api_komentarji.ApiKomentarjiRemoveAnnotation) {
        super(TxApiKomentarjiRemoveAnnotation.type, true);
        this.proto = proto;
    }

    static create(properties: api_komentarji.IApiKomentarjiRemoveAnnotation = {}) {
        return new TxApiKomentarjiRemoveAnnotation(api_komentarji.ApiKomentarjiRemoveAnnotation.create(properties));
    }
}


export class RxApiKomentarjiSessionAnnotations extends SocketRxMessage<api_komentarji.ApiKomentarjiSessionAnnotations> {
    static type: string = 'api-komentarji|session-annotations';
    proto = api_komentarji.ApiKomentarjiSessionAnnotations.create({});
    protoClass = api_komentarji.ApiKomentarjiSessionAnnotations;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiKomentarjiSessionAnnotations.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiKomentarjiSessionAnnotations(message);
    };
}


export class TxApiKomentarjiUpdateAnnotation extends SocketTxMessage<api_komentarji.ApiKomentarjiUpdateAnnotation> {
    static type: string = 'api-komentarji|update-annotation';
    proto: api_komentarji.ApiKomentarjiUpdateAnnotation;
    protoClass = api_komentarji.ApiKomentarjiUpdateAnnotation;
    

    constructor(proto: api_komentarji.ApiKomentarjiUpdateAnnotation) {
        super(TxApiKomentarjiUpdateAnnotation.type, true);
        this.proto = proto;
    }

    static create(properties: api_komentarji.IApiKomentarjiUpdateAnnotation = {}) {
        return new TxApiKomentarjiUpdateAnnotation(api_komentarji.ApiKomentarjiUpdateAnnotation.create(properties));
    }
}


export class TxApiKonstanteGetRoles extends SocketTxMessage<api_konstante.ApiKonstanteGetRoles> {
    static type: string = 'api-konstante|get-court-roles';
    proto: api_konstante.ApiKonstanteGetRoles;
    protoClass = api_konstante.ApiKonstanteGetRoles;
    

    constructor(proto: api_konstante.ApiKonstanteGetRoles) {
        super(TxApiKonstanteGetRoles.type, true);
        this.proto = proto;
    }

    static create(properties: api_konstante.IApiKonstanteGetRoles = {}) {
        return new TxApiKonstanteGetRoles(api_konstante.ApiKonstanteGetRoles.create(properties));
    }
}


export class RxApiKonstanteRoles extends SocketRxMessage<api_konstante.ApiKonstanteRoles> {
    static type: string = 'api-konstante|court-roles';
    proto = api_konstante.ApiKonstanteRoles.create({});
    protoClass = api_konstante.ApiKonstanteRoles;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiKonstanteRoles.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiKonstanteRoles(message);
    };
}


export class RxApiLogsAuditLogs extends SocketRxMessage<api_logs.ApiLogsAuditLogs> {
    static type: string = 'api-logs|audit-logs';
    proto = api_logs.ApiLogsAuditLogs.create({});
    protoClass = api_logs.ApiLogsAuditLogs;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiLogsAuditLogs.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiLogsAuditLogs(message);
    };
}


export class TxApiLogsGetAuditLogs extends SocketTxMessage<api_logs.ApiLogsGetAuditLogs> {
    static type: string = 'api-logs|get-audit-logs';
    proto: api_logs.ApiLogsGetAuditLogs;
    protoClass = api_logs.ApiLogsGetAuditLogs;
    

    constructor(proto: api_logs.ApiLogsGetAuditLogs) {
        super(TxApiLogsGetAuditLogs.type, true);
        this.proto = proto;
    }

    static create(properties: api_logs.IApiLogsGetAuditLogs = {}) {
        return new TxApiLogsGetAuditLogs(api_logs.ApiLogsGetAuditLogs.create(properties));
    }
}


export class TxApiObravnaveChangeSessionCourtCaseNumber extends SocketTxMessage<api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber> {
    static type: string = 'api-obravnave|change-session-court-case-number';
    proto: api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber;
    protoClass = api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber;
    

    constructor(proto: api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber) {
        super(TxApiObravnaveChangeSessionCourtCaseNumber.type, true);
        this.proto = proto;
    }

    static create(properties: api_obravnave.IApiObravnaveChangeSessionCourtCaseNumber = {}) {
        return new TxApiObravnaveChangeSessionCourtCaseNumber(api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber.create(properties));
    }
}


export class TxApiObravnaveCloseSession extends SocketTxMessage<api_obravnave.ApiObravnaveCloseSession> {
    static type: string = 'api-obravnave|close-session';
    proto: api_obravnave.ApiObravnaveCloseSession;
    protoClass = api_obravnave.ApiObravnaveCloseSession;
    

    constructor(proto: api_obravnave.ApiObravnaveCloseSession) {
        super(TxApiObravnaveCloseSession.type, true);
        this.proto = proto;
    }

    static create(properties: api_obravnave.IApiObravnaveCloseSession = {}) {
        return new TxApiObravnaveCloseSession(api_obravnave.ApiObravnaveCloseSession.create(properties));
    }
}


export class TxApiObravnaveCreateSession extends SocketTxMessage<api_obravnave.ApiObravnaveCreateSession> {
    static type: string = 'api-obravnave|create-session';
    proto: api_obravnave.ApiObravnaveCreateSession;
    protoClass = api_obravnave.ApiObravnaveCreateSession;
    

    constructor(proto: api_obravnave.ApiObravnaveCreateSession) {
        super(TxApiObravnaveCreateSession.type, true);
        this.proto = proto;
    }

    static create(properties: api_obravnave.IApiObravnaveCreateSession = {}) {
        return new TxApiObravnaveCreateSession(api_obravnave.ApiObravnaveCreateSession.create(properties));
    }
}


export class TxApiObravnaveInitiateSegmentUpload extends SocketTxMessage<api_obravnave.ApiObravnaveInitiateSegmentUpload> {
    static type: string = 'api-obravnave|initiate-segment-upload';
    proto: api_obravnave.ApiObravnaveInitiateSegmentUpload;
    protoClass = api_obravnave.ApiObravnaveInitiateSegmentUpload;
    

    constructor(proto: api_obravnave.ApiObravnaveInitiateSegmentUpload) {
        super(TxApiObravnaveInitiateSegmentUpload.type, true);
        this.proto = proto;
    }

    static create(properties: api_obravnave.IApiObravnaveInitiateSegmentUpload = {}) {
        return new TxApiObravnaveInitiateSegmentUpload(api_obravnave.ApiObravnaveInitiateSegmentUpload.create(properties));
    }
}


export class RxApiObravnaveSegmentUploadInfo extends SocketRxMessage<api_obravnave.ApiObravnaveSegmentUploadInfo> {
    static type: string = 'api-obravnave|segment-upload-info';
    proto = api_obravnave.ApiObravnaveSegmentUploadInfo.create({});
    protoClass = api_obravnave.ApiObravnaveSegmentUploadInfo;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiObravnaveSegmentUploadInfo.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiObravnaveSegmentUploadInfo(message);
    };
}


export class TxApiPairingCertificateSigningRequest extends SocketTxMessage<api_pairing.ApiPairingCertificateSigningRequest> {
    static type: string = 'api-pairing|certificate-signing-request';
    proto: api_pairing.ApiPairingCertificateSigningRequest;
    protoClass = api_pairing.ApiPairingCertificateSigningRequest;
    

    constructor(proto: api_pairing.ApiPairingCertificateSigningRequest) {
        super(TxApiPairingCertificateSigningRequest.type, true);
        this.proto = proto;
    }

    static create(properties: api_pairing.IApiPairingCertificateSigningRequest = {}) {
        return new TxApiPairingCertificateSigningRequest(api_pairing.ApiPairingCertificateSigningRequest.create(properties));
    }
}


export class RxApiPairingCourtrooms extends SocketRxMessage<api_pairing.ApiPairingCourtrooms> {
    static type: string = 'api-pairing|courtrooms';
    proto = api_pairing.ApiPairingCourtrooms.create({});
    protoClass = api_pairing.ApiPairingCourtrooms;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiPairingCourtrooms.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiPairingCourtrooms(message);
    };
}


export class RxApiPairingCourts extends SocketRxMessage<api_pairing.ApiPairingCourts> {
    static type: string = 'api-pairing|courts';
    proto = api_pairing.ApiPairingCourts.create({});
    protoClass = api_pairing.ApiPairingCourts;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiPairingCourts.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiPairingCourts(message);
    };
}


export class RxApiPairingCsrResponse extends SocketRxMessage<api_pairing.ApiPairingCsrResponse> {
    static type: string = 'api-pairing|csr-response';
    proto = api_pairing.ApiPairingCsrResponse.create({});
    protoClass = api_pairing.ApiPairingCsrResponse;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiPairingCsrResponse.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiPairingCsrResponse(message);
    };
}


export class TxApiPairingGetCertificate extends SocketTxMessage<api_pairing.ApiPairingGetCertificate> {
    static type: string = 'api-pairing|get-certificate';
    proto: api_pairing.ApiPairingGetCertificate;
    protoClass = api_pairing.ApiPairingGetCertificate;
    

    constructor(proto: api_pairing.ApiPairingGetCertificate) {
        super(TxApiPairingGetCertificate.type, true);
        this.proto = proto;
    }

    static create(properties: api_pairing.IApiPairingGetCertificate = {}) {
        return new TxApiPairingGetCertificate(api_pairing.ApiPairingGetCertificate.create(properties));
    }
}


export class TxApiPairingGetCourtrooms extends SocketTxMessage<api_pairing.ApiPairingGetCourtrooms> {
    static type: string = 'api-pairing|get-courtrooms';
    proto: api_pairing.ApiPairingGetCourtrooms;
    protoClass = api_pairing.ApiPairingGetCourtrooms;
    

    constructor(proto: api_pairing.ApiPairingGetCourtrooms) {
        super(TxApiPairingGetCourtrooms.type, true);
        this.proto = proto;
    }

    static create(properties: api_pairing.IApiPairingGetCourtrooms = {}) {
        return new TxApiPairingGetCourtrooms(api_pairing.ApiPairingGetCourtrooms.create(properties));
    }
}


export class TxApiPairingGetCourts extends SocketTxMessage<api_pairing.ApiPairingGetCourts> {
    static type: string = 'api-pairing|get-courts';
    proto: api_pairing.ApiPairingGetCourts;
    protoClass = api_pairing.ApiPairingGetCourts;
    

    constructor(proto: api_pairing.ApiPairingGetCourts) {
        super(TxApiPairingGetCourts.type, true);
        this.proto = proto;
    }

    static create(properties: api_pairing.IApiPairingGetCourts = {}) {
        return new TxApiPairingGetCourts(api_pairing.ApiPairingGetCourts.create(properties));
    }
}


export class TxApiPregledGetPackagedSession extends SocketTxMessage<api_pregled.ApiPregledGetPackagedSession> {
    static type: string = 'api-pregled|get-packaged-session';
    proto: api_pregled.ApiPregledGetPackagedSession;
    protoClass = api_pregled.ApiPregledGetPackagedSession;
    

    constructor(proto: api_pregled.ApiPregledGetPackagedSession) {
        super(TxApiPregledGetPackagedSession.type, true);
        this.proto = proto;
    }

    static create(properties: api_pregled.IApiPregledGetPackagedSession = {}) {
        return new TxApiPregledGetPackagedSession(api_pregled.ApiPregledGetPackagedSession.create(properties));
    }
}


export class TxApiPregledGetSessions extends SocketTxMessage<api_pregled.ApiPregledGetSessions> {
    static type: string = 'api-pregled|get-sessions';
    proto: api_pregled.ApiPregledGetSessions;
    protoClass = api_pregled.ApiPregledGetSessions;
    

    constructor(proto: api_pregled.ApiPregledGetSessions) {
        super(TxApiPregledGetSessions.type, true);
        this.proto = proto;
    }

    static create(properties: api_pregled.IApiPregledGetSessions = {}) {
        return new TxApiPregledGetSessions(api_pregled.ApiPregledGetSessions.create(properties));
    }
}


export class RxApiPregledPackagedSession extends SocketRxMessage<api_pregled.ApiPregledPackagedSession> {
    static type: string = 'api-pregled|packaged-session';
    proto = api_pregled.ApiPregledPackagedSession.create({});
    protoClass = api_pregled.ApiPregledPackagedSession;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiPregledPackagedSession.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiPregledPackagedSession(message);
    };
}


export class RxApiPregledSessions extends SocketRxMessage<api_pregled.ApiPregledSessions> {
    static type: string = 'api-pregled|sessions';
    proto = api_pregled.ApiPregledSessions.create({});
    protoClass = api_pregled.ApiPregledSessions;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiPregledSessions.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiPregledSessions(message);
    };
}


export class RxApiPrijavaAuthChallenge extends SocketRxMessage<api_prijava.ApiPrijavaAuthChallenge> {
    static type: string = 'api-prijava|auth-challenge';
    proto = api_prijava.ApiPrijavaAuthChallenge.create({});
    protoClass = api_prijava.ApiPrijavaAuthChallenge;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiPrijavaAuthChallenge.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiPrijavaAuthChallenge(message);
    };
}


export class RxApiPrijavaAuthToken extends SocketRxMessage<api_prijava.ApiPrijavaAuthToken> {
    static type: string = 'api-prijava|auth-token';
    proto = api_prijava.ApiPrijavaAuthToken.create({});
    protoClass = api_prijava.ApiPrijavaAuthToken;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiPrijavaAuthToken.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiPrijavaAuthToken(message);
    };
}


export class TxApiPrijavaRenewAuthToken extends SocketTxMessage<api_prijava.ApiPrijavaRenewAuthToken> {
    static type: string = 'api-prijava|renew-auth-token';
    proto: api_prijava.ApiPrijavaRenewAuthToken;
    protoClass = api_prijava.ApiPrijavaRenewAuthToken;
    

    constructor(proto: api_prijava.ApiPrijavaRenewAuthToken) {
        super(TxApiPrijavaRenewAuthToken.type, true);
        this.proto = proto;
    }

    static create(properties: api_prijava.IApiPrijavaRenewAuthToken = {}) {
        return new TxApiPrijavaRenewAuthToken(api_prijava.ApiPrijavaRenewAuthToken.create(properties));
    }
}


export class TxApiPrijavaRequestAuth extends SocketTxMessage<api_prijava.ApiPrijavaRequestAuth> {
    static type: string = 'api-prijava|request-auth';
    proto: api_prijava.ApiPrijavaRequestAuth;
    protoClass = api_prijava.ApiPrijavaRequestAuth;
    

    constructor(proto: api_prijava.ApiPrijavaRequestAuth) {
        super(TxApiPrijavaRequestAuth.type, true);
        this.proto = proto;
    }

    static create(properties: api_prijava.IApiPrijavaRequestAuth = {}) {
        return new TxApiPrijavaRequestAuth(api_prijava.ApiPrijavaRequestAuth.create(properties));
    }
}


export class TxApiSnemalnikiCancelUpdateRecorders extends SocketTxMessage<api_snemalniki.ApiSnemalnikiCancelUpdateRecorders> {
    static type: string = 'api-snemalniki|cancel-update-recorders';
    proto: api_snemalniki.ApiSnemalnikiCancelUpdateRecorders;
    protoClass = api_snemalniki.ApiSnemalnikiCancelUpdateRecorders;
    

    constructor(proto: api_snemalniki.ApiSnemalnikiCancelUpdateRecorders) {
        super(TxApiSnemalnikiCancelUpdateRecorders.type, true);
        this.proto = proto;
    }

    static create(properties: api_snemalniki.IApiSnemalnikiCancelUpdateRecorders = {}) {
        return new TxApiSnemalnikiCancelUpdateRecorders(api_snemalniki.ApiSnemalnikiCancelUpdateRecorders.create(properties));
    }
}


export class TxApiSnemalnikiGetRecorders extends SocketTxMessage<api_snemalniki.ApiSnemalnikiGetRecorders> {
    static type: string = 'api-snemalniki|get-recorders';
    proto: api_snemalniki.ApiSnemalnikiGetRecorders;
    protoClass = api_snemalniki.ApiSnemalnikiGetRecorders;
    

    constructor(proto: api_snemalniki.ApiSnemalnikiGetRecorders) {
        super(TxApiSnemalnikiGetRecorders.type, true);
        this.proto = proto;
    }

    static create(properties: api_snemalniki.IApiSnemalnikiGetRecorders = {}) {
        return new TxApiSnemalnikiGetRecorders(api_snemalniki.ApiSnemalnikiGetRecorders.create(properties));
    }
}


export class RxApiSnemalnikiRecorders extends SocketRxMessage<api_snemalniki.ApiSnemalnikiRecorders> {
    static type: string = 'api-snemalniki|recorders';
    proto = api_snemalniki.ApiSnemalnikiRecorders.create({});
    protoClass = api_snemalniki.ApiSnemalnikiRecorders;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiSnemalnikiRecorders.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiSnemalnikiRecorders(message);
    };
}


export class TxApiSnemalnikiUpdateAllRecorders extends SocketTxMessage<api_snemalniki.ApiSnemalnikiUpdateAllRecorders> {
    static type: string = 'api-snemalniki|update-all-recorders';
    proto: api_snemalniki.ApiSnemalnikiUpdateAllRecorders;
    protoClass = api_snemalniki.ApiSnemalnikiUpdateAllRecorders;
    

    constructor(proto: api_snemalniki.ApiSnemalnikiUpdateAllRecorders) {
        super(TxApiSnemalnikiUpdateAllRecorders.type, true);
        this.proto = proto;
    }

    static create(properties: api_snemalniki.IApiSnemalnikiUpdateAllRecorders = {}) {
        return new TxApiSnemalnikiUpdateAllRecorders(api_snemalniki.ApiSnemalnikiUpdateAllRecorders.create(properties));
    }
}


export class TxApiSnemalnikiUpdateRecorderDiskAmountFilled extends SocketTxMessage<api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled> {
    static type: string = 'api-snemalniki|update-recorder-disk-amount-filled';
    proto: api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled;
    protoClass = api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled;
    

    constructor(proto: api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled) {
        super(TxApiSnemalnikiUpdateRecorderDiskAmountFilled.type, true);
        this.proto = proto;
    }

    static create(properties: api_snemalniki.IApiSnemalnikiUpdateRecorderDiskAmountFilled = {}) {
        return new TxApiSnemalnikiUpdateRecorderDiskAmountFilled(api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled.create(properties));
    }
}


export class TxApiSnemalnikiUpdateRecorderDiskCapacity extends SocketTxMessage<api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity> {
    static type: string = 'api-snemalniki|update-recorder-disk-capacity';
    proto: api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity;
    protoClass = api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity;
    

    constructor(proto: api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity) {
        super(TxApiSnemalnikiUpdateRecorderDiskCapacity.type, true);
        this.proto = proto;
    }

    static create(properties: api_snemalniki.IApiSnemalnikiUpdateRecorderDiskCapacity = {}) {
        return new TxApiSnemalnikiUpdateRecorderDiskCapacity(api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity.create(properties));
    }
}


export class TxApiSnemalnikiUpdateRecorders extends SocketTxMessage<api_snemalniki.ApiSnemalnikiUpdateRecorders> {
    static type: string = 'api-snemalniki|update-recorders';
    proto: api_snemalniki.ApiSnemalnikiUpdateRecorders;
    protoClass = api_snemalniki.ApiSnemalnikiUpdateRecorders;
    

    constructor(proto: api_snemalniki.ApiSnemalnikiUpdateRecorders) {
        super(TxApiSnemalnikiUpdateRecorders.type, true);
        this.proto = proto;
    }

    static create(properties: api_snemalniki.IApiSnemalnikiUpdateRecorders = {}) {
        return new TxApiSnemalnikiUpdateRecorders(api_snemalniki.ApiSnemalnikiUpdateRecorders.create(properties));
    }
}


export class TxApiUdelezenciAddParticipant extends SocketTxMessage<api_udelezenci.ApiUdelezenciAddParticipant> {
    static type: string = 'api-udelezenci|add-participant';
    proto: api_udelezenci.ApiUdelezenciAddParticipant;
    protoClass = api_udelezenci.ApiUdelezenciAddParticipant;
    

    constructor(proto: api_udelezenci.ApiUdelezenciAddParticipant) {
        super(TxApiUdelezenciAddParticipant.type, true);
        this.proto = proto;
    }

    static create(properties: api_udelezenci.IApiUdelezenciAddParticipant = {}) {
        return new TxApiUdelezenciAddParticipant(api_udelezenci.ApiUdelezenciAddParticipant.create(properties));
    }
}


export class TxApiUdelezenciGetSessionParticipants extends SocketTxMessage<api_udelezenci.ApiUdelezenciGetSessionParticipants> {
    static type: string = 'api-udelezenci|get-session-participants';
    proto: api_udelezenci.ApiUdelezenciGetSessionParticipants;
    protoClass = api_udelezenci.ApiUdelezenciGetSessionParticipants;
    

    constructor(proto: api_udelezenci.ApiUdelezenciGetSessionParticipants) {
        super(TxApiUdelezenciGetSessionParticipants.type, true);
        this.proto = proto;
    }

    static create(properties: api_udelezenci.IApiUdelezenciGetSessionParticipants = {}) {
        return new TxApiUdelezenciGetSessionParticipants(api_udelezenci.ApiUdelezenciGetSessionParticipants.create(properties));
    }
}


export class TxApiUdelezenciRemoveParticipant extends SocketTxMessage<api_udelezenci.ApiUdelezenciRemoveParticipant> {
    static type: string = 'api-udelezenci|remove-participant';
    proto: api_udelezenci.ApiUdelezenciRemoveParticipant;
    protoClass = api_udelezenci.ApiUdelezenciRemoveParticipant;
    

    constructor(proto: api_udelezenci.ApiUdelezenciRemoveParticipant) {
        super(TxApiUdelezenciRemoveParticipant.type, true);
        this.proto = proto;
    }

    static create(properties: api_udelezenci.IApiUdelezenciRemoveParticipant = {}) {
        return new TxApiUdelezenciRemoveParticipant(api_udelezenci.ApiUdelezenciRemoveParticipant.create(properties));
    }
}


export class RxApiUdelezenciSessionParticipants extends SocketRxMessage<api_udelezenci.ApiUdelezenciSessionParticipants> {
    static type: string = 'api-udelezenci|session-participants';
    proto = api_udelezenci.ApiUdelezenciSessionParticipants.create({});
    protoClass = api_udelezenci.ApiUdelezenciSessionParticipants;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxApiUdelezenciSessionParticipants.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxApiUdelezenciSessionParticipants(message);
    };
}


export class TxApiUdelezenciSetParticipantTaxId extends SocketTxMessage<api_udelezenci.ApiUdelezenciSetParticipantTaxId> {
    static type: string = 'api-udelezenci|set-participant-tax-id';
    proto: api_udelezenci.ApiUdelezenciSetParticipantTaxId;
    protoClass = api_udelezenci.ApiUdelezenciSetParticipantTaxId;
    

    constructor(proto: api_udelezenci.ApiUdelezenciSetParticipantTaxId) {
        super(TxApiUdelezenciSetParticipantTaxId.type, true);
        this.proto = proto;
    }

    static create(properties: api_udelezenci.IApiUdelezenciSetParticipantTaxId = {}) {
        return new TxApiUdelezenciSetParticipantTaxId(api_udelezenci.ApiUdelezenciSetParticipantTaxId.create(properties));
    }
}


export class TxApiUdelezenciUpdateParticipant extends SocketTxMessage<api_udelezenci.ApiUdelezenciUpdateParticipant> {
    static type: string = 'api-udelezenci|update-participant';
    proto: api_udelezenci.ApiUdelezenciUpdateParticipant;
    protoClass = api_udelezenci.ApiUdelezenciUpdateParticipant;
    

    constructor(proto: api_udelezenci.ApiUdelezenciUpdateParticipant) {
        super(TxApiUdelezenciUpdateParticipant.type, true);
        this.proto = proto;
    }

    static create(properties: api_udelezenci.IApiUdelezenciUpdateParticipant = {}) {
        return new TxApiUdelezenciUpdateParticipant(api_udelezenci.ApiUdelezenciUpdateParticipant.create(properties));
    }
}


export class RxAsodAudioChunk extends SocketRxMessage<asod_audio.AsodAudioChunk> {
    static type: string = 'asod-audio-chunk';
    proto = asod_audio.AsodAudioChunk.create({});
    protoClass = asod_audio.AsodAudioChunk;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodAudioChunk.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodAudioChunk(message);
    };
}


export class TxAsodLogout extends SocketTxMessage<asod_auth.AsodLogout> {
    static type: string = 'asod-logout';
    proto: asod_auth.AsodLogout;
    protoClass = asod_auth.AsodLogout;
    

    constructor(proto: asod_auth.AsodLogout) {
        super(TxAsodLogout.type, true);
        this.proto = proto;
    }

    static create(properties: asod_auth.IAsodLogout = {}) {
        return new TxAsodLogout(asod_auth.AsodLogout.create(properties));
    }
}


export class TxSaveAsodMember extends SocketTxMessage<asod_members.SaveAsodMember> {
    static type: string = 'save-asod-member';
    proto: asod_members.SaveAsodMember;
    protoClass = asod_members.SaveAsodMember;
    

    constructor(proto: asod_members.SaveAsodMember) {
        super(TxSaveAsodMember.type, true);
        this.proto = proto;
    }

    static create(properties: asod_members.ISaveAsodMember = {}) {
        return new TxSaveAsodMember(asod_members.SaveAsodMember.create(properties));
    }
}


export class RxAsodProject extends SocketRxMessage<asod_project.AsodProject> {
    static type: string = 'asod-project';
    proto = asod_project.AsodProject.create({});
    protoClass = asod_project.AsodProject;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodProject.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodProject(message);
    };
}


export class TxAsodProjectAddComment extends SocketTxMessage<asod_project.AsodProjectAddComment> {
    static type: string = 'asod-project-add-comment';
    proto: asod_project.AsodProjectAddComment;
    protoClass = asod_project.AsodProjectAddComment;
    

    constructor(proto: asod_project.AsodProjectAddComment) {
        super(TxAsodProjectAddComment.type, true);
        this.proto = proto;
    }

    static create(properties: asod_project.IAsodProjectAddComment = {}) {
        return new TxAsodProjectAddComment(asod_project.AsodProjectAddComment.create(properties));
    }
}


export class RxAsodProjectComment extends SocketRxMessage<asod_project.AsodProjectComment> {
    static type: string = 'asod-project-comment';
    proto = asod_project.AsodProjectComment.create({});
    protoClass = asod_project.AsodProjectComment;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodProjectComment.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodProjectComment(message);
    };
}


export class RxAsodProjectMeta extends SocketRxMessage<asod_project.AsodProjectMeta> {
    static type: string = 'asod-project-meta';
    proto = asod_project.AsodProjectMeta.create({});
    protoClass = asod_project.AsodProjectMeta;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodProjectMeta.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodProjectMeta(message);
    };
}


export class TxAsodProjectSaveComment extends SocketTxMessage<asod_project.AsodProjectSaveComment> {
    static type: string = 'asod-project-save-comment';
    proto: asod_project.AsodProjectSaveComment;
    protoClass = asod_project.AsodProjectSaveComment;
    

    constructor(proto: asod_project.AsodProjectSaveComment) {
        super(TxAsodProjectSaveComment.type, true);
        this.proto = proto;
    }

    static create(properties: asod_project.IAsodProjectSaveComment = {}) {
        return new TxAsodProjectSaveComment(asod_project.AsodProjectSaveComment.create(properties));
    }
}


export class TxLoadAsodProject extends SocketTxMessage<asod_project.LoadAsodProject> {
    static type: string = 'load-asod-project';
    proto: asod_project.LoadAsodProject;
    protoClass = asod_project.LoadAsodProject;
    

    constructor(proto: asod_project.LoadAsodProject) {
        super(TxLoadAsodProject.type, true);
        this.proto = proto;
    }

    static create(properties: asod_project.ILoadAsodProject = {}) {
        return new TxLoadAsodProject(asod_project.LoadAsodProject.create(properties));
    }
}


export class RxAsodRecorderAudioChannels extends SocketRxMessage<asod_recorder_audio.AsodRecorderAudioChannels> {
    static type: string = 'asod-recorder-audio-channels';
    proto = asod_recorder_audio.AsodRecorderAudioChannels.create({});
    protoClass = asod_recorder_audio.AsodRecorderAudioChannels;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodRecorderAudioChannels.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodRecorderAudioChannels(message);
    };
}


export class RxAsodRecorderAudioDevices extends SocketRxMessage<asod_recorder_audio.AsodRecorderAudioDevices> {
    static type: string = 'asod-recorder-audio-devices';
    proto = asod_recorder_audio.AsodRecorderAudioDevices.create({});
    protoClass = asod_recorder_audio.AsodRecorderAudioDevices;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodRecorderAudioDevices.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodRecorderAudioDevices(message);
    };
}


export class TxAsodRecorderAudioToggleRecording extends SocketTxMessage<asod_recorder_audio.AsodRecorderAudioToggleRecording> {
    static type: string = 'asod-recorder-audio-toggle-recording';
    proto: asod_recorder_audio.AsodRecorderAudioToggleRecording;
    protoClass = asod_recorder_audio.AsodRecorderAudioToggleRecording;
    

    constructor(proto: asod_recorder_audio.AsodRecorderAudioToggleRecording) {
        super(TxAsodRecorderAudioToggleRecording.type, true);
        this.proto = proto;
    }

    static create(properties: asod_recorder_audio.IAsodRecorderAudioToggleRecording = {}) {
        return new TxAsodRecorderAudioToggleRecording(asod_recorder_audio.AsodRecorderAudioToggleRecording.create(properties));
    }
}


export class RxAsodRecorderProjectAudio extends SocketRxMessage<asod_recorder_project.AsodRecorderProjectAudio> {
    static type: string = 'asod-recorder-project-audio';
    proto = asod_recorder_project.AsodRecorderProjectAudio.create({});
    protoClass = asod_recorder_project.AsodRecorderProjectAudio;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodRecorderProjectAudio.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodRecorderProjectAudio(message);
    };
}


export class RxAsodRecorderProjectAudioBlock extends SocketRxMessage<asod_recorder_project.AsodRecorderProjectAudioBlock> {
    static type: string = 'asod-recorder-project-audio-block';
    proto = asod_recorder_project.AsodRecorderProjectAudioBlock.create({});
    protoClass = asod_recorder_project.AsodRecorderProjectAudioBlock;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodRecorderProjectAudioBlock.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodRecorderProjectAudioBlock(message);
    };
}


export class RxAsodRecorderProjectAudioChunk extends SocketRxMessage<asod_recorder_project.AsodRecorderProjectAudioChunk> {
    static type: string = 'asod-recorder-project-audio-chunk';
    proto = asod_recorder_project.AsodRecorderProjectAudioChunk.create({});
    protoClass = asod_recorder_project.AsodRecorderProjectAudioChunk;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodRecorderProjectAudioChunk.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodRecorderProjectAudioChunk(message);
    };
}


export class TxAsodRecorderProjectComplete extends SocketTxMessage<asod_recorder_project.AsodRecorderProjectComplete> {
    static type: string = 'asod-recorder-project-complete';
    proto: asod_recorder_project.AsodRecorderProjectComplete;
    protoClass = asod_recorder_project.AsodRecorderProjectComplete;
    

    constructor(proto: asod_recorder_project.AsodRecorderProjectComplete) {
        super(TxAsodRecorderProjectComplete.type, true);
        this.proto = proto;
    }

    static create(properties: asod_recorder_project.IAsodRecorderProjectComplete = {}) {
        return new TxAsodRecorderProjectComplete(asod_recorder_project.AsodRecorderProjectComplete.create(properties));
    }
}


export class TxLogin extends SocketTxMessage<authentication.Login> {
    static type: string = 'login';
    proto: authentication.Login;
    protoClass = authentication.Login;
    

    constructor(proto: authentication.Login) {
        super(TxLogin.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.ILogin = {}) {
        return new TxLogin(authentication.Login.create(properties));
    }
}


export class RxLoginError extends SocketRxMessage<authentication.LoginError> {
    static type: string = 'login-error';
    proto = authentication.LoginError.create({});
    protoClass = authentication.LoginError;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginError.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginError(message);
    };
}


export class RxLoginToken extends SocketRxMessage<authentication.LoginToken> {
    static type: string = 'login-token';
    proto = authentication.LoginToken.create({});
    protoClass = authentication.LoginToken;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginToken.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginToken(message);
    };
}


export class TxRefreshToken extends SocketTxMessage<authentication.RefreshToken> {
    static type: string = 'refresh-token';
    proto: authentication.RefreshToken;
    protoClass = authentication.RefreshToken;
    

    constructor(proto: authentication.RefreshToken) {
        super(TxRefreshToken.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.IRefreshToken = {}) {
        return new TxRefreshToken(authentication.RefreshToken.create(properties));
    }
}


export class RxRefreshTokenInvalid extends SocketRxMessage<authentication.RefreshTokenInvalid> {
    static type: string = 'refresh-token-invalid';
    proto = authentication.RefreshTokenInvalid.create({});
    protoClass = authentication.RefreshTokenInvalid;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxRefreshTokenInvalid.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxRefreshTokenInvalid(message);
    };
}


export class RxTokenInvalid extends SocketRxMessage<authentication.TokenInvalid> {
    static type: string = 'token-invalid';
    proto = authentication.TokenInvalid.create({});
    protoClass = authentication.TokenInvalid;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxTokenInvalid.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxTokenInvalid(message);
    };
}


export class TxVerifyToken extends SocketTxMessage<authentication.VerifyToken> {
    static type: string = 'verify-token';
    proto: authentication.VerifyToken;
    protoClass = authentication.VerifyToken;
    

    constructor(proto: authentication.VerifyToken) {
        super(TxVerifyToken.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.IVerifyToken = {}) {
        return new TxVerifyToken(authentication.VerifyToken.create(properties));
    }
}


export class TxRecorderDeleteCsr extends SocketTxMessage<recorder_pairing.RecorderDeleteCsr> {
    static type: string = 'recorder-delete-csr';
    proto: recorder_pairing.RecorderDeleteCsr;
    protoClass = recorder_pairing.RecorderDeleteCsr;
    

    constructor(proto: recorder_pairing.RecorderDeleteCsr) {
        super(TxRecorderDeleteCsr.type, true);
        this.proto = proto;
    }

    static create(properties: recorder_pairing.IRecorderDeleteCsr = {}) {
        return new TxRecorderDeleteCsr(recorder_pairing.RecorderDeleteCsr.create(properties));
    }
}


export class RxRecorderOnPairingCertPasswordRequest extends SocketRxMessage<recorder_pairing.RecorderOnPairingCertPasswordRequest> {
    static type: string = 'recorder-on-pairing-cert-password-request';
    proto = recorder_pairing.RecorderOnPairingCertPasswordRequest.create({});
    protoClass = recorder_pairing.RecorderOnPairingCertPasswordRequest;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxRecorderOnPairingCertPasswordRequest.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxRecorderOnPairingCertPasswordRequest(message);
    };
}


export class TxRecorderPair extends SocketTxMessage<recorder_pairing.RecorderPair> {
    static type: string = 'recorder-pair';
    proto: recorder_pairing.RecorderPair;
    protoClass = recorder_pairing.RecorderPair;
    

    constructor(proto: recorder_pairing.RecorderPair) {
        super(TxRecorderPair.type, true);
        this.proto = proto;
    }

    static create(properties: recorder_pairing.IRecorderPair = {}) {
        return new TxRecorderPair(recorder_pairing.RecorderPair.create(properties));
    }
}


export class TxRecorderPairingCertPasswordRequest extends SocketTxMessage<recorder_pairing.RecorderPairingCertPasswordRequest> {
    static type: string = 'recorder-pairing-cert-password-request';
    proto: recorder_pairing.RecorderPairingCertPasswordRequest;
    protoClass = recorder_pairing.RecorderPairingCertPasswordRequest;
    

    constructor(proto: recorder_pairing.RecorderPairingCertPasswordRequest) {
        super(TxRecorderPairingCertPasswordRequest.type, true);
        this.proto = proto;
    }

    static create(properties: recorder_pairing.IRecorderPairingCertPasswordRequest = {}) {
        return new TxRecorderPairingCertPasswordRequest(recorder_pairing.RecorderPairingCertPasswordRequest.create(properties));
    }
}


export class RxOnRecorderBoot extends SocketRxMessage<recorder_state.OnRecorderBoot> {
    static type: string = 'on-recorder-boot';
    proto = recorder_state.OnRecorderBoot.create({});
    protoClass = recorder_state.OnRecorderBoot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxOnRecorderBoot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxOnRecorderBoot(message);
    };
}


export class RxRecorderProjectUploadErrors extends SocketRxMessage<recorder_state.RecorderProjectUploadErrors> {
    static type: string = 'recorder-project-upload-errors';
    proto = recorder_state.RecorderProjectUploadErrors.create({});
    protoClass = recorder_state.RecorderProjectUploadErrors;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxRecorderProjectUploadErrors.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxRecorderProjectUploadErrors(message);
    };
}


export class RxRecorderProjectUploadState extends SocketRxMessage<recorder_state.RecorderProjectUploadState> {
    static type: string = 'recorder-project-upload-state';
    proto = recorder_state.RecorderProjectUploadState.create({});
    protoClass = recorder_state.RecorderProjectUploadState;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxRecorderProjectUploadState.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxRecorderProjectUploadState(message);
    };
}


export class RxRecorderRecordingState extends SocketRxMessage<recorder_state.RecorderRecordingState> {
    static type: string = 'recorder-recording-state';
    proto = recorder_state.RecorderRecordingState.create({});
    protoClass = recorder_state.RecorderRecordingState;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxRecorderRecordingState.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxRecorderRecordingState(message);
    };
}


export class RxRecorderState extends SocketRxMessage<recorder_state.RecorderState> {
    static type: string = 'recorder-state';
    proto = recorder_state.RecorderState.create({});
    protoClass = recorder_state.RecorderState;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxRecorderState.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxRecorderState(message);
    };
}


export class RxRecorderWebserviceState extends SocketRxMessage<recorder_state.RecorderWebserviceState> {
    static type: string = 'recorder-webservice-state';
    proto = recorder_state.RecorderWebserviceState.create({});
    protoClass = recorder_state.RecorderWebserviceState;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxRecorderWebserviceState.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxRecorderWebserviceState(message);
    };
}


export class TxDeleteFile extends SocketTxMessage<sfiles.DeleteFile> {
    static type: string = 'delete-file';
    proto: sfiles.DeleteFile;
    protoClass = sfiles.DeleteFile;
    

    constructor(proto: sfiles.DeleteFile) {
        super(TxDeleteFile.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IDeleteFile = {}) {
        return new TxDeleteFile(sfiles.DeleteFile.create(properties));
    }
}


export class RxUploadDone extends SocketRxMessage<sfiles.UploadDone> {
    static type: string = 'upload-done';
    proto = sfiles.UploadDone.create({});
    protoClass = sfiles.UploadDone;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadDone.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadDone(message);
    };
}


export class TxUploadEnd extends SocketTxMessage<sfiles.UploadEnd> {
    static type: string = 'upload-end';
    proto: sfiles.UploadEnd;
    protoClass = sfiles.UploadEnd;
    

    constructor(proto: sfiles.UploadEnd) {
        super(TxUploadEnd.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadEnd = {}) {
        return new TxUploadEnd(sfiles.UploadEnd.create(properties));
    }
}


export class RxUploadProgress extends SocketRxMessage<sfiles.UploadProgress> {
    static type: string = 'upload-progress';
    proto = sfiles.UploadProgress.create({});
    protoClass = sfiles.UploadProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadProgress(message);
    };
}


export class TxUploadStart extends SocketTxMessage<sfiles.UploadStart> {
    static type: string = 'upload-start';
    proto: sfiles.UploadStart;
    protoClass = sfiles.UploadStart;
    

    constructor(proto: sfiles.UploadStart) {
        super(TxUploadStart.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadStart = {}) {
        return new TxUploadStart(sfiles.UploadStart.create(properties));
    }
}


export class RxUploadStartSlot extends SocketRxMessage<sfiles.UploadStartSlot> {
    static type: string = 'upload-start';
    proto = sfiles.UploadStartSlot.create({});
    protoClass = sfiles.UploadStartSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadStartSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadStartSlot(message);
    };
}


export class RxAck extends SocketRxMessage<socket_api.Ack> {
    static type: string = 'ack';
    proto = socket_api.Ack.create({});
    protoClass = socket_api.Ack;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAck.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAck(message);
    };
}


export class RxAsyncProgress extends SocketRxMessage<socket_api.AsyncProgress> {
    static type: string = 'async-progress';
    proto = socket_api.AsyncProgress.create({});
    protoClass = socket_api.AsyncProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsyncProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsyncProgress(message);
    };
}


export class RxIlolAck extends SocketRxMessage<socket_api.IlolAck> {
    static type: string = 'ilol|ack';
    proto = socket_api.IlolAck.create({});
    protoClass = socket_api.IlolAck;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxIlolAck.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxIlolAck(message);
    };
}


export class RxIlolError extends SocketRxMessage<socket_api.IlolError> {
    static type: string = 'ilol|error';
    proto = socket_api.IlolError.create({});
    protoClass = socket_api.IlolError;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxIlolError.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxIlolError(message);
    };
}


export class RxUpgradeApiVersion extends SocketRxMessage<socket_api.UpgradeApiVersion> {
    static type: string = 'upgrade-api-version';
    proto = socket_api.UpgradeApiVersion.create({});
    protoClass = socket_api.UpgradeApiVersion;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUpgradeApiVersion.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUpgradeApiVersion(message);
    };
}


export class TxDeleteUFile extends SocketTxMessage<uploader.DeleteUFile> {
    static type: string = 'delete-ufile';
    proto: uploader.DeleteUFile;
    protoClass = uploader.DeleteUFile;
    

    constructor(proto: uploader.DeleteUFile) {
        super(TxDeleteUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IDeleteUFile = {}) {
        return new TxDeleteUFile(uploader.DeleteUFile.create(properties));
    }
}


export class RxUploadSlot extends SocketRxMessage<uploader.UploadSlot> {
    static type: string = 'upload-slot';
    proto = uploader.UploadSlot.create({});
    protoClass = uploader.UploadSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadSlot(message);
    };
}


export class RxUploadTask extends SocketRxMessage<uploader.UploadTask> {
    static type: string = 'upload-task';
    proto = uploader.UploadTask.create({});
    protoClass = uploader.UploadTask;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadTask.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadTask(message);
    };
}


export class TxUploadUFile extends SocketTxMessage<uploader.UploadUFile> {
    static type: string = 'upload-ufile';
    proto: uploader.UploadUFile;
    protoClass = uploader.UploadUFile;
    

    constructor(proto: uploader.UploadUFile) {
        super(TxUploadUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IUploadUFile = {}) {
        return new TxUploadUFile(uploader.UploadUFile.create(properties));
    }
}


export class RxAsodProjects extends SocketRxMessage<ws_home.AsodProjects> {
    static type: string = 'asod-projects';
    proto = ws_home.AsodProjects.create({});
    protoClass = ws_home.AsodProjects;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodProjects.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodProjects(message);
    };
}


export class TxLoadAsodProjects extends SocketTxMessage<ws_home.LoadAsodProjects> {
    static type: string = 'load-asod-projects';
    proto: ws_home.LoadAsodProjects;
    protoClass = ws_home.LoadAsodProjects;
    

    constructor(proto: ws_home.LoadAsodProjects) {
        super(TxLoadAsodProjects.type, true);
        this.proto = proto;
    }

    static create(properties: ws_home.ILoadAsodProjects = {}) {
        return new TxLoadAsodProjects(ws_home.LoadAsodProjects.create(properties));
    }
}


export class RxAsodLogs extends SocketRxMessage<ws_logs.AsodLogs> {
    static type: string = 'asod-logs';
    proto = ws_logs.AsodLogs.create({});
    protoClass = ws_logs.AsodLogs;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodLogs.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodLogs(message);
    };
}


export class TxLoadAsodLogs extends SocketTxMessage<ws_logs.LoadAsodLogs> {
    static type: string = 'load-asod-logs';
    proto: ws_logs.LoadAsodLogs;
    protoClass = ws_logs.LoadAsodLogs;
    

    constructor(proto: ws_logs.LoadAsodLogs) {
        super(TxLoadAsodLogs.type, true);
        this.proto = proto;
    }

    static create(properties: ws_logs.ILoadAsodLogs = {}) {
        return new TxLoadAsodLogs(ws_logs.LoadAsodLogs.create(properties));
    }
}


export class RxAsodManagement extends SocketRxMessage<ws_management.AsodManagement> {
    static type: string = 'asod-management';
    proto = ws_management.AsodManagement.create({});
    protoClass = ws_management.AsodManagement;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodManagement.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodManagement(message);
    };
}


export class TxLoadAsodManagement extends SocketTxMessage<ws_management.LoadAsodManagement> {
    static type: string = 'load-asod-management';
    proto: ws_management.LoadAsodManagement;
    protoClass = ws_management.LoadAsodManagement;
    

    constructor(proto: ws_management.LoadAsodManagement) {
        super(TxLoadAsodManagement.type, true);
        this.proto = proto;
    }

    static create(properties: ws_management.ILoadAsodManagement = {}) {
        return new TxLoadAsodManagement(ws_management.LoadAsodManagement.create(properties));
    }
}


export class RxAsodUsers extends SocketRxMessage<ws_users.AsodUsers> {
    static type: string = 'asod-users';
    proto = ws_users.AsodUsers.create({});
    protoClass = ws_users.AsodUsers;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsodUsers.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsodUsers(message);
    };
}


export class TxLoadAsodUsers extends SocketTxMessage<ws_users.LoadAsodUsers> {
    static type: string = 'load-asod-users';
    proto: ws_users.LoadAsodUsers;
    protoClass = ws_users.LoadAsodUsers;
    

    constructor(proto: ws_users.LoadAsodUsers) {
        super(TxLoadAsodUsers.type, true);
        this.proto = proto;
    }

    static create(properties: ws_users.ILoadAsodUsers = {}) {
        return new TxLoadAsodUsers(ws_users.LoadAsodUsers.create(properties));
    }
}


        export const rxMessages: SocketRxMessage<any>[] = [
            new RxApiDeeplinksSessionDeeplinks(),
    new RxApiGeneralAck(),
    new RxApiGeneralWhoAmIResponse(),
    new RxApiGeneralWhoArePersonUuidsResponse(),
    new RxApiIntegritetaVerificationHistory(),
    new RxApiKomentarjiSessionAnnotations(),
    new RxApiKonstanteRoles(),
    new RxApiLogsAuditLogs(),
    new RxApiObravnaveSegmentUploadInfo(),
    new RxApiPairingCourtrooms(),
    new RxApiPairingCourts(),
    new RxApiPairingCsrResponse(),
    new RxApiPregledPackagedSession(),
    new RxApiPregledSessions(),
    new RxApiPrijavaAuthChallenge(),
    new RxApiPrijavaAuthToken(),
    new RxApiSnemalnikiRecorders(),
    new RxApiUdelezenciSessionParticipants(),
    new RxAsodAudioChunk(),
    new RxAsodProject(),
    new RxAsodProjectComment(),
    new RxAsodProjectMeta(),
    new RxAsodRecorderAudioChannels(),
    new RxAsodRecorderAudioDevices(),
    new RxAsodRecorderProjectAudio(),
    new RxAsodRecorderProjectAudioBlock(),
    new RxAsodRecorderProjectAudioChunk(),
    new RxLoginError(),
    new RxLoginToken(),
    new RxRefreshTokenInvalid(),
    new RxTokenInvalid(),
    new RxRecorderOnPairingCertPasswordRequest(),
    new RxOnRecorderBoot(),
    new RxRecorderProjectUploadErrors(),
    new RxRecorderProjectUploadState(),
    new RxRecorderRecordingState(),
    new RxRecorderState(),
    new RxRecorderWebserviceState(),
    new RxUploadDone(),
    new RxUploadProgress(),
    new RxUploadStartSlot(),
    new RxAck(),
    new RxAsyncProgress(),
    new RxIlolAck(),
    new RxIlolError(),
    new RxUpgradeApiVersion(),
    new RxUploadSlot(),
    new RxUploadTask(),
    new RxAsodProjects(),
    new RxAsodLogs(),
    new RxAsodManagement(),
    new RxAsodUsers()
        ];
}